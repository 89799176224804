import { Button, Modal, TextInput } from '@octano/global-ui';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { Modality } from '../../type';
import { useModalitiesLoader } from '../Loaders/ModalitiesLoader';

interface Props {
  actions: {
    createModality: (data: any) => Promise<void>;
    updateModality: (params: {
      modalityId: number;
      data: any;
    }) => Promise<void>;
  };
}

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openModal: (row?: Modality) => void;
}
interface FormData {
  code: string;
  name: string;
  description?: string;
}

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { t } = useTranslation();
    const forms = useForm<FormData>();
    const {
      control,
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState<Modality | null>(null);
    const { data } = useModalitiesLoader();
    const codes = data?.data.map((Modality) => Modality.code) ?? [];

    useEffect(() => {
      if (formData) {
        reset({
          code: formData.code,
          name: formData.name,
          description: formData.description ?? undefined,
        });
      } else
        reset({
          code: undefined,
          name: undefined,
          description: undefined,
        });
    }, [formData, reset]);

    useImperativeHandle(ref, () => ({
      openModal(row: any) {
        setFormData(row);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    const onSubmit = async (values: FormData) => {
      const data = {
        id: formData ? formData.id : undefined,
        code: values.code,
        name: values.name,
        description: values.description,
      };
      if (formData) {
        await actions.updateModality({
          modalityId: formData.id,
          data,
        });
      } else {
        await actions.createModality(data);
      }

      reset();
      setModal(false);
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            {formData ? 'Editar Modalidad' : 'Ingresar nueva Modalidad'}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6} xs={12}>
                <TextInput
                  name="code"
                  label={'Código'}
                  placeholder={''}
                  control={control}
                  rules={{
                    required: t('common.validations.required'),
                    validate: (value) =>
                      value && codes.includes(value) && formData?.code !== value
                        ? t('El código ya se encuentra en uso')
                        : true,
                  }}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="name"
                  label={'Nombre'}
                  placeholder={''}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>

              <Col xs={12} style={{ marginBottom: '20px' }}>
                <label htmlFor="description">Descripción (Opcional)</label>
                <textarea
                  rows={4}
                  {...control.register('description')}
                  className="form-control"
                />
              </Col>
              <hr className="py-5" />
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={'Cancelar'}
                  fullwidth
                  onClick={() => setModal(false)}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={'Guardar'}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
