import request from '../../../api/request';
import { PaginationQuery } from '../../../types/pagination';
import { ModalitiesResponse } from './type';

const MODALITIES_CONTAINER = {
  GET: '/mantainer-portal/maintainers/modalities',
  POST: '/mantainer-portal/maintainers/modalities',
  PUT: '/mantainer-portal/maintainers/modalities',
};

export function getModalitiesList(params: PaginationQuery) {
  return request<ModalitiesResponse>(MODALITIES_CONTAINER.GET, {
    method: 'GET',
    params: { ...params, page: params.page - 1 },
  });
}

export function createModality(data: any) {
  return request(MODALITIES_CONTAINER.POST, {
    method: 'POST',
    data,
  });
}

export function updateModality(id: number, data: any) {
  return request(`${MODALITIES_CONTAINER.PUT}/${id}`, {
    method: 'PATCH',
    data,
  });
}

export function deleteModality(modalityId: number) {
  return request(`${MODALITIES_CONTAINER.PUT}/${modalityId}`, {
    method: 'DELETE',
  });
}
