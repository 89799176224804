import { ReactElement, useCallback } from 'react';
import {
  AuthenticationError,
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../api/request';
import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import { WorkingDaysResponse } from '../../type';
import { PaginationQuery } from '../../../../../types/pagination';
import DisplayError from '../../../../../components/info/DisplayError';
import { getWorkingDaysList } from '../../api';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  PaginationQuery,
  WorkingDaysResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useWorkingDaysLoader() {
  return useFetch();
}

export const WorkingDaysLoaderConsumer = FetchConsumer;

export default function WorkingDaysLoader({ children }: BaseLoaderProps) {
  const request = useCallback(
    async (
      query: PaginationQuery,
    ): Promise<AxiosResult<WorkingDaysResponse, AxiosResultDefaultError>> => {
      return getWorkingDaysList(query);
    },
    [],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{
        page: 1,
        itemsPerPage: 10,
      }}
      fetchImmediately
    >
      <WorkingDaysLoaderConsumer>
        {({ error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                typeError={(error as any)?.code}
                retryAction={refresh}
              />
            );
          }
          return children;
        }}
      </WorkingDaysLoaderConsumer>
    </FetchProvider>
  );
}
