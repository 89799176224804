import request from '../../../api/request';
import { PaginationQuery } from '../../../types/pagination';
import { SpecialtyAreasResponse } from './type';

const SPECIALTY_AREAS_CONTAINER = {
  GET: '/mantainer-portal/maintainers/specialty-areas',
  POST: '/mantainer-portal/maintainers/specialty-areas',
  PUT: '/mantainer-portal/maintainers/specialty-areas',
};

export function getSpecialtyAreasList(params: PaginationQuery) {
  return request<SpecialtyAreasResponse>(SPECIALTY_AREAS_CONTAINER.GET, {
    method: 'GET',
    params: { ...params, page: params.page - 1 },
  });
}

export function createSpecialtyArea(data: any) {
  return request(SPECIALTY_AREAS_CONTAINER.POST, {
    method: 'POST',
    data,
  });
}

export function updateSpecialtyArea(id: number, data: any) {
  return request(`${SPECIALTY_AREAS_CONTAINER.PUT}/${id}`, {
    method: 'PATCH',
    data,
  });
}

export function deleteSpecialtyArea(specialtyAreaId: number) {
  return request(`${SPECIALTY_AREAS_CONTAINER.PUT}/${specialtyAreaId}`, {
    method: 'DELETE',
  });
}
