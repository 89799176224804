import { Button, Modal, TextInput } from '@octano/global-ui';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { School } from '../../type';
import { useSchoolsLoader } from '../Loaders/SchoolsLoader';

interface Props {
  actions: {
    createSchool: (data: any) => Promise<void>;
    updateSchool: (params: { schoolId: number; data: any }) => Promise<void>;
  };
}

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openModal: (row?: School) => void;
}
interface FormData {
  code: string;
  name: string;
  description?: string;
}

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { t } = useTranslation();
    const forms = useForm<FormData>();
    const {
      control,
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState<School | null>(null);
    const { data } = useSchoolsLoader();
    const codes = data?.data.map((School) => School.code) ?? [];

    useEffect(() => {
      if (formData) {
        reset({
          code: formData.code,
          name: formData.name,
        });
      } else
        reset({
          code: undefined,
          name: undefined,
        });
    }, [formData, reset]);

    useImperativeHandle(ref, () => ({
      openModal(row: any) {
        setFormData(row);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    const onSubmit = async (values: FormData) => {
      const data = {
        id: formData ? formData.id : undefined,
        code: values.code,
        name: values.name,
        description: values.description,
      };
      if (formData) {
        await actions.updateSchool({
          schoolId: formData.id,
          data,
        });
      } else {
        await actions.createSchool(data);
      }

      reset();
      setModal(false);
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            {formData ? 'Editar Escuela' : 'Ingresar nueva Escuela'}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6} xs={12}>
                <TextInput
                  name="code"
                  label={'Código'}
                  placeholder={''}
                  control={control}
                  rules={{
                    required: t('common.validations.required'),
                    validate: (value) =>
                      value && codes.includes(value) && formData?.code !== value
                        ? t('El código ya se encuentra en uso')
                        : true,
                  }}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="name"
                  label={'Nombre'}
                  placeholder={''}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <hr className="py-5" />
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={'Cancelar'}
                  fullwidth
                  onClick={() => setModal(false)}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={'Guardar'}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
