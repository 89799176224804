import request from '../../../api/request';
import { PaginationQuery } from '../../../types/pagination';
import { WorkingDaysFiltersResponse, WorkingDaysResponse } from './type';

const WORKING_DAYS_CONTAINER = {
  GET: '/mantainer-portal/maintainers/working-days',
  POST: '/mantainer-portal/maintainers/working-days',
  PUT: '/mantainer-portal/maintainers/working-days',
  FORM_DATA: '/mantainer-portal/maintainers/working-days/form-data',
};

export function getWorkingDaysList(params: PaginationQuery) {
  return request<WorkingDaysResponse>(WORKING_DAYS_CONTAINER.GET, {
    method: 'GET',
    params: { ...params, page: params.page - 1 },
  });
}

export function createWorkingDay(data: any) {
  return request(WORKING_DAYS_CONTAINER.POST, {
    method: 'POST',
    data,
  });
}

export function updateWorkingDay(id: number, data: any) {
  return request(`${WORKING_DAYS_CONTAINER.PUT}/${id}`, {
    method: 'PATCH',
    data,
  });
}

export function deleteWorkingDay(workingDayId: number) {
  return request(`${WORKING_DAYS_CONTAINER.PUT}/${workingDayId}`, {
    method: 'DELETE',
  });
}

export function getFormData() {
  return request<WorkingDaysFiltersResponse>(WORKING_DAYS_CONTAINER.FORM_DATA, {
    method: 'GET',
  });
}
