import { useCallback } from 'react';
import { createWorkingDay, deleteWorkingDay, updateWorkingDay } from '../api';
import { useWorkingDaysFilterLoader } from '../parts/Loaders/useWorkingDaysFilterLoader';

interface UseActionsParams {
  createWorkingDay: any;
  updateWorkingDay: { workingDayId: number; data: any };
}
type ActionType =
  | 'create'
  | 'update'
  | 'delete'
  | 'Jornada creado con éxito'
  | 'Jornada modificado con éxito'
  | 'Jornada eliminada con éxito';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const { data: offersData } = useWorkingDaysFilterLoader();

  const createWorkingDayAction = useCallback(
    async (data: UseActionsParams['createWorkingDay']) => {
      const result = await createWorkingDay(data);
      if (!result.error) {
        return props.onSuccess('Jornada creado con éxito');
      }
      props.onError('create', result.error.data.message);
    },
    [props],
  );

  const updateWorkingDayAction = useCallback(
    async (params: UseActionsParams['updateWorkingDay']) => {
      const result = await updateWorkingDay(params.workingDayId, params.data);
      if (!result.error) {
        return props.onSuccess('Jornada modificado con éxito');
      }
      props.onError('update', result.error.data.message);
    },
    [props],
  );

  const deleteWorkingDayAction = useCallback(
    async (workindDayId: number) => {
      const result = await deleteWorkingDay(workindDayId);
      if (!result.error) {
        return props.onSuccess('Jornada eliminada con éxito');
      }
      props.onError('delete', result.error.data.message);
    },
    [props],
  );

  return {
    createWorkingDay: createWorkingDayAction,
    updateWorkingDay: updateWorkingDayAction,
    deleteWorkingDay: deleteWorkingDayAction,
  };
}
