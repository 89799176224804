import request from '../../../api/request';
import { PaginationQuery } from '../../../types/pagination';
import { SchoolsResponse } from './type';

const SCHOOLS_CONTAINER = {
  GET: '/mantainer-portal/maintainers/schools',
  POST: '/mantainer-portal/maintainers/schools',
  PUT: '/mantainer-portal/maintainers/schools',
};

export function getSchoolsList(params: PaginationQuery) {
  return request<SchoolsResponse>(SCHOOLS_CONTAINER.GET, {
    method: 'GET',
    params: { ...params, page: params.page - 1 },
  });
}

export function createSchool(data: any) {
  return request(SCHOOLS_CONTAINER.POST, {
    method: 'POST',
    data,
  });
}

export function updateSchool(id: number, data: any) {
  return request(`${SCHOOLS_CONTAINER.PUT}/${id}`, {
    method: 'PATCH',
    data,
  });
}

export function deleteSchool(schoolId: number) {
  return request(`${SCHOOLS_CONTAINER.PUT}/${schoolId}`, {
    method: 'DELETE',
  });
}
