import { addToast, Button, Table } from '@octano/global-ui';
import { useRef } from 'react';
import useActions from '../hooks/useActions';
import useColumns from '../hooks/useColumns';
import { useWorkingDaysLoader } from './Loaders/WorkingDaysLoader';
import ConfirmDeleteModal, { ConfirmModalRef } from './Modals/ConfirmModal';
import CreateOrUpdateModal, {
  CreateOrUpdateRef,
} from './Modals/CreateOrUpdateModal';
import { WorkingDay } from '../type';

function WorkingDaysTable() {
  const { data, query, updateQuery, loading, refresh } = useWorkingDaysLoader();

  const actions = useActions({
    onSuccess: (message) => {
      refresh();
      addToast({
        icon: 'success',
        color: 'success',
        text: message,
      });
    },
    onError: (_, message) => {
      addToast({
        icon: 'error',
        color: 'danger',
        text: message ?? 'Ha ocurrido un error',
      });
    },
  });

  const ConfirmModalRef = useRef<ConfirmModalRef | undefined>();
  const createOrUpdateModalRef = useRef<CreateOrUpdateRef | undefined>();

  const onClickEdit = (row: WorkingDay) => {
    createOrUpdateModalRef.current?.openModal(row);
  };
  const onClickRemove = (row: WorkingDay) => {
    ConfirmModalRef.current?.openModal(row);
  };

  const handleConfirmDelete = (workindDayId: number) => {
    actions.deleteWorkingDay(workindDayId);
  };

  const columns = useColumns({ onClickEdit, onClickRemove });

  return (
    <>
      <Button
        text="Ingresar Nueva Jornada"
        color="primary"
        icon="plus"
        size="md"
        className="mb-3"
        style={{ minWidth: '254px' }}
        onClick={() => createOrUpdateModalRef.current?.openModal()}
      />
      <Table
        columns={columns}
        data={data?.data ?? []}
        isLoadingResults={loading}
        loadingView={{
          title: 'Cargando jornadas...',
          subtitle: 'Espere un momento',
        }}
        pagination={{
          currentPage: query?.page ?? 1,
          itemsPerPage: query.itemsPerPage ?? 10,
          totalPages: data?.totalPages ?? 0,
          totalItems: data?.total ?? 0,
          onChangePage: (page) => {
            updateQuery({ ...query, page });
          },
        }}
      />
      <CreateOrUpdateModal
        ref={createOrUpdateModalRef as React.Ref<CreateOrUpdateRef>}
        actions={actions}
      />
      <ConfirmDeleteModal
        onConfirm={(row) => {
          handleConfirmDelete(row.id);
        }}
        ref={ConfirmModalRef as React.Ref<ConfirmModalRef>}
      />
    </>
  );
}

export default WorkingDaysTable;
