import { memo } from 'react';
import { Container } from 'reactstrap';
import SpecialtyAreasLoader from './parts/Loaders/SpecialtyAreasLoader';
import SpecialtyAreasTable from './parts/SpecialtyAreasTable';
function Modalities() {
  return (
    <Container fluid className="mt-4">
      <SpecialtyAreasLoader>
        <SpecialtyAreasTable />
      </SpecialtyAreasLoader>
    </Container>
  );
}
export default memo(Modalities);
