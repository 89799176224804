import { useCallback } from 'react';
import { createCareer, deleteCareer, updateCareer } from '../api';

interface UseActionsParams {
  createCareer: any;
  updateCareer: { careerId: number; data: any };
}
type ActionType =
  | 'create'
  | 'update'
  | 'delete'
  | 'Carrera creada con éxito'
  | 'Carrera modificada con éxito'
  | 'Carrera eliminada con éxito';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const createCareerAction = useCallback(
    async (data: UseActionsParams['createCareer']) => {
      const result = await createCareer(data);
      if (!result.error) {
        return props.onSuccess('Carrera creada con éxito');
      }
      props.onError('create', result.error.data.message);
    },
    [props],
  );

  const updateCareerAction = useCallback(
    async (params: UseActionsParams['updateCareer']) => {
      const result = await updateCareer(params.careerId, params.data);
      if (!result.error) {
        return props.onSuccess('Carrera modificada con éxito');
      }
      props.onError('update', result.error.data.message);
    },
    [props],
  );

  const deleteCareerAction = useCallback(
    async (careerId: number) => {
      const result = await deleteCareer(careerId);
      if (!result.error) {
        return props.onSuccess('Carrera eliminada con éxito');
      }
      props.onError('delete', result.error.data.message);
    },
    [props],
  );

  return {
    createCareer: createCareerAction,
    updateCareer: updateCareerAction,
    deleteCareer: deleteCareerAction,
  };
}
