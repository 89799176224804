import { SidenavLayout as GlobalSidenavLayout } from '@octano/global-ui';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Container } from 'reactstrap';

import RoutesByLayout from '../components/RoutesByLayout/RoutesByLayout';
import TopBar from '../components/Topbar/TopBar';
import {
  CategoryWithPermissions,
  LinkWithPermissions,
  SIDENAV_LINKS,
} from '../config/sidenav';
import useUserState from '../hooks/useUserState';

import { useMemo } from 'react';
import { PathsLayouts } from '../config/routes';
import { withAuthCheckRedirection } from '../hocs/withAuthCheckRedirection';
//import { isPostulant } from '../utils/appUser';

interface TopBarTitlesDict {
  [route: string]: string;
}

const TITLES_KEY_DICTIONARY: TopBarTitlesDict = {
  'published-offers': 'currentOffersRoute',
  'draft-offers': 'draftOffersRoute',
  'create-offer': 'createOffersRoute',
  'offer-list': 'selectionRoute',
  'offer-detail': 'selectionRoute',
  'postulants-authorization': 'postulantsAuthorizationRoute',
  'postulation-detail': 'postulantsAuthorizationRoute',
  'authorized-postulants': 'authorizedPostulantsRoute',
  users: 'users',
  grades: 'grades',
  'contract-viewfinders': 'contractViewfinders',
  rates: 'rates',
  'assign-sections': 'authorizedPostulantsRoute',
  password: 'password',
  history: 'history',
  reports: 'reports',
  sections: 'sections',
  subjects: 'subjects',
  periods: 'periods',
  general: 'general',
  academics: 'academics',
  campus: 'campuses',
  workingDays: 'workingDays',
  modalities: 'modalities',
  specialtyAreas: 'specialtyAreas',
  schools: 'schools',
  careers: 'careers',
};

function SidenavLayout() {
  const { isAuthorizedTo } = useUserState();
  const { t } = useTranslation();
  const dictPrefix = 'sidenav.linkNames';
  const loc = useHistory();
  const { location } = loc;
  const currentPath = location.pathname.split('/')[1];
  const currentLocation = location.pathname.split('/')[2];
  const pageTitle = useMemo(() => {
    let prefix = 'pageTitles.title';
    switch (currentPath) {
      case 'academic-authorization':
        prefix += '.academicAuthorization';
        break;
      case 'hiring':
        prefix += '.hiring';
        break;
      case 'settings':
        prefix += '.settings';
        break;
      case 'profile':
        prefix += '.profile';
        break;
      case 'maintainer':
        prefix += '.maintainer';
        break;
    }
    return t(`${prefix}.${TITLES_KEY_DICTIONARY[currentLocation]}`);
  }, [currentLocation, currentPath, t]);

  const isCategory = (
    item: LinkWithPermissions | CategoryWithPermissions,
  ): item is CategoryWithPermissions => {
    return !!(item as CategoryWithPermissions).links;
  };

  const isAuthorizedLink = (link: LinkWithPermissions) => {
    if (!link.requiredPermissions?.length) return true;

    return isAuthorizedTo(link.requiredPermissions, link.allPermisionsRequired);
  };

  const getCategoryWithAuthorizedLinks = (
    category: CategoryWithPermissions,
  ) => {
    const authorizedLinks = category.links.filter(isAuthorizedLink);

    return { ...category, links: authorizedLinks };
  };

  const getItemWithoutPermissionsInfo = (
    item: LinkWithPermissions | CategoryWithPermissions,
  ) => {
    if (isCategory(item)) {
      const newLinks = item.links.map((link) => ({
        name: t(`${dictPrefix}.${link.name}`) ?? '',
        path: link.path,
      }));

      const name = t(`${dictPrefix}.${item.name}`) ?? '';
      return { ...item, name, links: newLinks };
    } else {
      const link = item;
      const name = t(`${dictPrefix}.${link.name}`) ?? '';

      return { name, path: link.path, icon: link.icon };
    }
  };

  const getSidenavAuthorizedLinks = () => {
    const filteredItems = SIDENAV_LINKS.reduce((list, item) => {
      if (isCategory(item)) {
        const categoryWithFilteredLinks = getCategoryWithAuthorizedLinks(item);

        if (!categoryWithFilteredLinks.links.length) return list;

        return [...list, categoryWithFilteredLinks];
      }

      if (isAuthorizedLink(item as LinkWithPermissions)) {
        return [...list, item];
      }

      return list;
    }, [] as (LinkWithPermissions | CategoryWithPermissions)[]);

    return filteredItems.map(getItemWithoutPermissionsInfo);
  };

  return (
    <GlobalSidenavLayout links={getSidenavAuthorizedLinks()}>
      <div className="d-flex flex-column h-100">
        {/* 
          Para algunas aplicaciones de la suite Global 3000 el titulo del topbar es unico por modulo y
          y para otras es diferente por vista. Deben añadir aca el computo de ese titulo en base a lo que
          se requiera para cada aplicacion. 

          Es importante que ese titulo tambien se tome del diccionario de traducciones.
        */}
        <TopBar title={pageTitle} />
        <Container fluid className="flex-grow-1">
          <RoutesByLayout />
        </Container>
      </div>
    </GlobalSidenavLayout>
  );
}

export default withAuthCheckRedirection({
  component: SidenavLayout,
  calculateRedirectTo: ({ isLogged, userData }) => {
    if (!isLogged && !userData) {
      return PathsLayouts.auth;
    }
  },
});
