import { useCallback } from 'react';
import {
  createSpecialtyArea,
  deleteSpecialtyArea,
  updateSpecialtyArea,
} from '../api';

interface UseActionsParams {
  createSpecialtyArea: any;
  updateSpecialtyArea: { specialtyAreaId: number; data: any };
}
type ActionType =
  | 'create'
  | 'update'
  | 'delete'
  | 'Área de Especialidad creada con éxito'
  | 'Área de Especialidad modificada con éxito'
  | 'Área de Especialidad eliminada con éxito';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const createSpecialtyAreaAction = useCallback(
    async (data: UseActionsParams['createSpecialtyArea']) => {
      const result = await createSpecialtyArea(data);
      if (!result.error) {
        return props.onSuccess('Área de Especialidad creada con éxito');
      }
      props.onError('create', result.error.data.message);
    },
    [props],
  );

  const updateSpecialtyAreaAction = useCallback(
    async (params: UseActionsParams['updateSpecialtyArea']) => {
      const result = await updateSpecialtyArea(
        params.specialtyAreaId,
        params.data,
      );
      if (!result.error) {
        return props.onSuccess('Área de Especialidad modificada con éxito');
      }
      props.onError('update', result.error.data.message);
    },
    [props],
  );

  const deleteSpecialtyAreaAction = useCallback(
    async (specialtyAreaId: number) => {
      const result = await deleteSpecialtyArea(specialtyAreaId);
      if (!result.error) {
        return props.onSuccess('Área de Especialidad eliminada con éxito');
      }
      props.onError('delete', result.error.data.message);
    },
    [props],
  );

  return {
    createSpecialtyArea: createSpecialtyAreaAction,
    updateSpecialtyArea: updateSpecialtyAreaAction,
    deleteSpecialtyArea: deleteSpecialtyAreaAction,
  };
}
