import { ReactElement, useCallback } from 'react';
import {
  AuthenticationError,
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../api/request';
import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import { ModalitiesResponse } from '../../type';
import { PaginationQuery } from '../../../../../types/pagination';
import DisplayError from '../../../../../components/info/DisplayError';
import { getModalitiesList } from '../../api';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  PaginationQuery,
  ModalitiesResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useModalitiesLoader() {
  return useFetch();
}

export const ModalitiesLoaderConsumer = FetchConsumer;

export default function ModalitiesLoader({ children }: BaseLoaderProps) {
  const request = useCallback(
    async (
      query: PaginationQuery,
    ): Promise<AxiosResult<ModalitiesResponse, AxiosResultDefaultError>> => {
      return getModalitiesList(query);
    },
    [],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{
        page: 1,
        itemsPerPage: 10,
      }}
      fetchImmediately
    >
      <ModalitiesLoaderConsumer>
        {({ error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                typeError={(error as any)?.code}
                retryAction={refresh}
              />
            );
          }
          return children;
        }}
      </ModalitiesLoaderConsumer>
    </FetchProvider>
  );
}
