import { useCallback } from 'react';
import { createSchool, deleteSchool, updateSchool } from '../api';

interface UseActionsParams {
  createSchool: any;
  updateSchool: { schoolId: number; data: any };
}
type ActionType =
  | 'create'
  | 'update'
  | 'delete'
  | 'Escuela creada con éxito'
  | 'Escuela modificada con éxito'
  | 'Escuela eliminada con éxito';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const createSchoolAction = useCallback(
    async (data: UseActionsParams['createSchool']) => {
      const result = await createSchool(data);
      if (!result.error) {
        return props.onSuccess('Escuela creada con éxito');
      }
      props.onError('create', result.error.data.message);
    },
    [props],
  );

  const updateSchoolAction = useCallback(
    async (params: UseActionsParams['updateSchool']) => {
      const result = await updateSchool(params.schoolId, params.data);
      if (!result.error) {
        return props.onSuccess('Escuela modificada con éxito');
      }
      props.onError('update', result.error.data.message);
    },
    [props],
  );

  const deleteSchoolAction = useCallback(
    async (schoolId: number) => {
      const result = await deleteSchool(schoolId);
      if (!result.error) {
        return props.onSuccess('Escuela eliminada con éxito');
      }
      props.onError('delete', result.error.data.message);
    },
    [props],
  );

  return {
    createSchool: createSchoolAction,
    updateSchool: updateSchoolAction,
    deleteSchool: deleteSchoolAction,
  };
}
