import { memo } from 'react';
import { Container } from 'reactstrap';
import WorkingDaysLoader from './parts/Loaders/WorkingDaysLoader';
import WorkingDaysTable from './parts/WorkingDaysTable';
import WorkingDaysFilterLoader from './parts/Loaders/useWorkingDaysFilterLoader';
function WorkingDays() {
  return (
    <Container fluid className="mt-4">
      <WorkingDaysFilterLoader>
        <WorkingDaysLoader>
          <WorkingDaysTable />
        </WorkingDaysLoader>
      </WorkingDaysFilterLoader>
    </Container>
  );
}
export default memo(WorkingDays);
