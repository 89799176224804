import { memo } from 'react';
import { Container } from 'reactstrap';
import CareersLoader from './parts/Loaders/CareersLoader';
import CareersTable from './parts/CareersTable';
import CareersFilterLoader from './parts/Loaders/CareersFiltersLoader';
function Modalities() {
  return (
    <Container fluid className="mt-4">
      <CareersFilterLoader>
        <CareersLoader>
          <CareersTable />
        </CareersLoader>
      </CareersFilterLoader>
    </Container>
  );
}
export default memo(Modalities);
