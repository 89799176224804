import { memo } from 'react';
import { Container } from 'reactstrap';
import SchoolsLoader from './parts/Loaders/SchoolsLoader';
import SchoolsTable from './parts/SchoolsTable';
function Modalities() {
  return (
    <Container fluid className="mt-4">
      <SchoolsLoader>
        <SchoolsTable />
      </SchoolsLoader>
    </Container>
  );
}
export default memo(Modalities);
