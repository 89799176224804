import request from '../../../api/request';
import { PaginationQuery } from '../../../types/pagination';
import { CareerFiltersResponse, CareersResponse } from './type';

const CAREERS_CONTAINER = {
  GET: '/mantainer-portal/maintainers/careers',
  POST: '/mantainer-portal/maintainers/careers',
  PUT: '/mantainer-portal/maintainers/careers',
  FORM_DATA: '/mantainer-portal/maintainers/careers/form-data',
};

export function getCareersList(params: PaginationQuery) {
  return request<CareersResponse>(CAREERS_CONTAINER.GET, {
    method: 'GET',
    params: { ...params, page: params.page - 1 },
  });
}

export function createCareer(data: any) {
  return request(CAREERS_CONTAINER.POST, {
    method: 'POST',
    data,
  });
}

export function updateCareer(id: number, data: any) {
  return request(`${CAREERS_CONTAINER.PUT}/${id}`, {
    method: 'PATCH',
    data,
  });
}

export function deleteCareer(careerId: number) {
  return request(`${CAREERS_CONTAINER.PUT}/${careerId}`, {
    method: 'DELETE',
  });
}

export function getFormData() {
  return request<CareerFiltersResponse>(CAREERS_CONTAINER.FORM_DATA, {
    method: 'GET',
  });
}
