import { ReactElement, useCallback } from 'react';
import {
  AuthenticationError,
  AxiosResult,
  AxiosResultDefaultError,
} from '../../../../../api/request';
import { createFetchContext } from '../../../../../components/contexts/FetchContextFactory';
import { SchoolsResponse } from '../../type';
import { PaginationQuery } from '../../../../../types/pagination';
import DisplayError from '../../../../../components/info/DisplayError';
import { getSchoolsList } from '../../api';

interface BaseLoaderProps {
  children: ReactElement;
}

const { FetchProvider, FetchConsumer, useFetch } = createFetchContext<
  PaginationQuery,
  SchoolsResponse,
  AuthenticationError | AxiosResultDefaultError
>();

export function useSchoolsLoader() {
  return useFetch();
}

export const SchoolsLoaderConsumer = FetchConsumer;

export default function SchoolsLoader({ children }: BaseLoaderProps) {
  const request = useCallback(
    async (
      query: PaginationQuery,
    ): Promise<AxiosResult<SchoolsResponse, AxiosResultDefaultError>> => {
      return getSchoolsList(query);
    },
    [],
  );

  return (
    <FetchProvider
      request={request}
      defaultQuery={{
        page: 1,
        itemsPerPage: 10,
      }}
      fetchImmediately
    >
      <SchoolsLoaderConsumer>
        {({ error, refresh }) => {
          if (error) {
            return (
              <DisplayError
                typeError={(error as any)?.code}
                retryAction={refresh}
              />
            );
          }
          return children;
        }}
      </SchoolsLoaderConsumer>
    </FetchProvider>
  );
}
