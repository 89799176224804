import {
  Button,
  Modal,
  Select,
  SelectOptionType,
  TextInput,
} from '@octano/global-ui';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { Career } from '../../type';
import { useCareersLoader } from '../Loaders/CareersLoader';
import { useCareerFiltersLoader } from '../Loaders/CareersFiltersLoader';

interface Props {
  actions: {
    createCareer: (data: any) => Promise<void>;
    updateCareer: (params: { careerId: number; data: any }) => Promise<void>;
  };
}

export interface CreateOrUpdateRef {
  closeModal: () => void;
  openModal: (row?: Career) => void;
}
interface FormData {
  code: string;
  name: string;
  school: SelectOptionType | undefined;
  description?: string;
}

const CreateOrUpdateModal = forwardRef<CreateOrUpdateRef, Props>(
  ({ actions }, ref) => {
    const { t } = useTranslation();
    const forms = useForm<FormData>();
    const {
      control,
      reset,
      handleSubmit,
      formState: { isSubmitting },
    } = forms;
    const [modal, setModal] = useState(false);
    const [formData, setFormData] = useState<Career | null>(null);
    const { data } = useCareersLoader();
    const codes = data?.data.map((Career) => Career.code) ?? [];

    const { data: schoolsData } = useCareerFiltersLoader();

    const schools = useMemo(
      () =>
        schoolsData?.schools.map((c) => ({ label: c.name, value: c.id })) || [],
      [schoolsData?.schools],
    );

    useEffect(() => {
      if (formData) {
        reset({
          code: formData.code,
          name: formData.name,
          school: {
            label: formData.school.name,
            value: formData.school.id,
          },
        });
      } else
        reset({
          code: undefined,
          name: undefined,
          school: undefined,
        });
    }, [formData, reset]);

    useImperativeHandle(ref, () => ({
      openModal(row: any) {
        setFormData(row);
        setModal(true);
      },
      closeModal() {
        setModal(false);
      },
    }));

    const onSubmit = async (values: FormData) => {
      const data = {
        id: formData ? formData.id : undefined,
        code: values.code,
        name: values.name,
        schoolId: values.school?.value,
        description: values.description,
      };
      if (formData) {
        await actions.updateCareer({
          careerId: formData.id,
          data,
        });
      } else {
        await actions.createCareer(data);
      }

      reset();
      setModal(false);
    };

    return (
      <Modal isOpen={modal} toggle={() => setModal(!modal)} size="lg">
        <Container fluid>
          <h1 className="text-dark fs-20 mt-3 mb-4 text-center">
            {formData ? 'Editar Carrera' : 'Ingresar nueva Carrera'}
          </h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={6} xs={12}>
                <TextInput
                  name="code"
                  label={'Código'}
                  placeholder={''}
                  control={control}
                  rules={{
                    required: t('common.validations.required'),
                    validate: (value) =>
                      typeof value === 'string' &&
                      value &&
                      codes.includes(value) &&
                      formData?.code !== value
                        ? t('El código ya se encuentra en uso')
                        : true,
                  }}
                />
              </Col>
              <Col md={6} xs={12}>
                <TextInput
                  name="name"
                  label={'Nombre'}
                  placeholder={''}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <Col md={12} xs={12}>
                <Select
                  name="school"
                  options={schools}
                  label={'Escuela'}
                  placeholder={'Nombre de la Escuela'}
                  control={control}
                  rules={{ required: t('common.validations.required') }}
                />
              </Col>
              <hr className="py-5" />
              <Col xs={12} md={6}>
                <Button
                  outlined
                  text={'Cancelar'}
                  fullwidth
                  onClick={() => setModal(false)}
                  className="mb-3"
                />
              </Col>
              <Col xs={12} md={6}>
                <Button
                  type="submit"
                  text={'Guardar'}
                  className="mb-3"
                  fullwidth
                  loading={isSubmitting}
                  disabled={isSubmitting}
                />
              </Col>
            </Row>
          </form>
        </Container>
      </Modal>
    );
  },
);

export default CreateOrUpdateModal;
