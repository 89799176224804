import { memo } from 'react';
import { Container } from 'reactstrap';
import ModalitiesLoader from './parts/Loaders/ModalitiesLoader';
import ModalitiesTable from './parts/ModalitiesTable';
function Modalities() {
  return (
    <Container fluid className="mt-4">
      <ModalitiesLoader>
        <ModalitiesTable />
      </ModalitiesLoader>
    </Container>
  );
}
export default memo(Modalities);
