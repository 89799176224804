import { useCallback } from 'react';
import { createModality, deleteModality, updateModality } from '../api';

interface UseActionsParams {
  createModality: any;
  updateModality: { modalityId: number; data: any };
}
type ActionType =
  | 'create'
  | 'update'
  | 'delete'
  | 'Modalidad creado con éxito'
  | 'Modalidad modificado con éxito'
  | 'Modalidad eliminada con éxito';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const createModalityAction = useCallback(
    async (data: UseActionsParams['createModality']) => {
      const result = await createModality(data);
      if (!result.error) {
        return props.onSuccess('Modalidad creado con éxito');
      }
      props.onError('create', result.error.data.message);
    },
    [props],
  );

  const updateModalityAction = useCallback(
    async (params: UseActionsParams['updateModality']) => {
      const result = await updateModality(params.modalityId, params.data);
      if (!result.error) {
        return props.onSuccess('Modalidad modificado con éxito');
      }
      props.onError('update', result.error.data.message);
    },
    [props],
  );

  const deleteModalityAction = useCallback(
    async (modalityId: number) => {
      const result = await deleteModality(modalityId);
      if (!result.error) {
        return props.onSuccess('Modalidad eliminada con éxito');
      }
      props.onError('delete', result.error.data.message);
    },
    [props],
  );

  return {
    createModality: createModalityAction,
    updateModality: updateModalityAction,
    deleteModality: deleteModalityAction,
  };
}
